import Balancer from 'react-wrap-balancer';
import cn from 'classnames';
import { m } from 'framer-motion';
import { useElementHeight, useTranslation } from '@utils/hooks';
import { Body, Button, Heading, Title } from '@components';
import { expandButtonVariants, expandVariants, fadeVariants } from '@utils/motions';

import styles from './InteractiveCard.module.scss';

const getTranslatedTexts = translator => {
	return {
		translations: translator('loading-text-lowercase'),
	};
};

export const InteractiveCardExpandedContent = ({
	asHero,
	bgColor,
	button1,
	button2,
	elementHeight,
	isExpanded,
	subheading,
	subtitle,
	showTitle,
	shouldDisplayTitle,
	title,
	text,
	withBalancer,
}) => {
	const [titleRef, titleHeight] = useElementHeight();
	const hasButtons = !!button1;
	const hasSubheading = !!subheading;
	const body = withBalancer ? <Balancer>{text}</Balancer> : text;
	const descriptionHeight = elementHeight - titleHeight - 50;
	const { translator } = useTranslation();
	const translations = getTranslatedTexts(translator);

	return (
		<m.div
			key={`content-${title}-${elementHeight}`}
			className={cn(styles.content, { [styles['content--asHero']]: asHero })}
			animate={isExpanded ? 'expanded' : 'collapsed'}
			initial={false}
			variants={expandVariants(bgColor, hasButtons, hasSubheading, elementHeight, titleHeight)}
		>
			{shouldDisplayTitle && (
				<Heading
					ref={titleRef}
					className={cn(styles.title)}
					tag='h4'
					removeDefaultMargin
					style={{ opacity: showTitle ? '1' : '0' }}
				>
					{title}
				</Heading>
			)}
			{subheading && (
				<Heading
					className={styles.s}
					tag='h6'
					removeDefaultMargin
					withBalancer
					style={{ opacity: showTitle ? '1' : '0' }}
				>
					{subheading}
				</Heading>
			)}
			{button1 && (
				<m.div
					className={styles.buttons}
					animate={isExpanded ? 'expanded' : 'collapsed'}
					initial={false}
					variants={expandButtonVariants(hasSubheading, elementHeight)}
				>
					<Button
						extraClasses={cn(button1.className)}
						size='small'
						label={button1.label}
						href={button1.link}
						color={button1.color || 'transparent-light'}
						onClick={e => e.stopPropagation()}
						loadingText={translations.translations}
					/>

					{button2 && (
						<Button
							extraClasses={cn(button2.className)}
							size='small'
							label={button2.label}
							href={button2.link}
							color={button2.color || 'transparent-light'}
							onClick={e => e.stopPropagation()}
							loadingText={translations.translations}
						/>
					)}
				</m.div>
			)}
			<m.div animate={isExpanded ? 'fadeIn' : 'fadeOut'} initial={false} variants={fadeVariants} style={{ height: '100%' }}>
				{subtitle && <Title>{subtitle}</Title>}
				<Body
					className={styles['body']}
					style={{
						WebkitLineClamp: Math.round(descriptionHeight / 24),
					}}
				>
					{body}
				</Body>
			</m.div>
		</m.div>
	);
};
