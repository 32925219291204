import cn from 'classnames';
import { m } from 'framer-motion';
import { zoomVariants } from '@utils/motions';
import { isVideoUrl } from '@utils/urls';
import { MEDIUM_WIDTH } from '@constants';
import { Video as VideoType } from '@ts/shopify-storefront-api';
import { Video } from '@components';
import styles from './InteractiveCard.module.scss';

type InteractiveCardMediaProps = {
	children?: React.ReactNode;
	background?: string;
	videoUrl?: string;
	mediaClassName?: string;
	backgroundMobile?: string;
	sourcesVideo?: VideoType['sources'];
	altText?: string;
	topRightButton?: boolean;
};

export const InteractiveCardMedia = ({
	children,
	background,
	videoUrl,
	mediaClassName,
	backgroundMobile,
	sourcesVideo,
	altText,
	topRightButton = false,
}: InteractiveCardMediaProps) => {
	if (children) {
		return (
			<m.div variants={zoomVariants} style={{ height: 'inherit' }}>
				{children}
			</m.div>
		);
	}

	if (isVideoUrl(background) || sourcesVideo?.length > 0 || videoUrl) {
		return <Video video={{ sources: sourcesVideo } as VideoType} topRightButton={topRightButton} />;
	}

	return (
		background && (
			<m.picture className={cn(mediaClassName, styles.bgImage)} variants={zoomVariants}>
				<source srcSet={background} media={`(min-width: ${MEDIUM_WIDTH}px)`} />
				<m.img
					src={backgroundMobile || background}
					alt={altText}
					width='100%'
					height='100%'
					style={{ objectFit: 'cover' }}
				/>
			</m.picture>
		)
	);
};
